@font-face {
  font-family: 'diamwoodFont';
  src:  url('../../fonts/diamwoodFont.eot?s8g4zj');
  src:  url('../../fonts/diamwoodFont.eot?s8g4zj#iefix') format('embedded-opentype'),
    url('../../fonts/diamwoodFont.ttf?s8g4zj') format('truetype'),
    url('../../fonts/diamwoodFont.woff?s8g4zj') format('woff'),
    url('../../fonts/diamwoodFont.svg?s8g4zj#diamwoodFont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="font-"], [class*=" font-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'diamwoodFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-electricite:before {
  content: "\e900";
}
.font-epi:before {
  content: "\e901";
}
.font-fixation:before {
  content: "\e902";
}
.font-machine_atelier:before {
  content: "\e903";
}
.font-machine_chantier:before {
  content: "\e904";
}
.font-manutention:before {
  content: "\e905";
}
.font-motoculture:before {
  content: "\e906";
}
.font-outillage_machine:before {
  content: "\e907";
}
.font-outillage_main:before {
  content: "\e908";
}
.font-plomberie:before {
  content: "\e909";
}
.font-quincaillerie:before {
  content: "\e90a";
}
